import React from 'react';
import Social from '../../components/Social/Social';

import emma_about from "../../resources/emma_about.jpg"
import "./style.css"

export default () => {
  return (
    <div className="About text-page">
      <div className="landing-image">
        <img src={emma_about} />
      </div>
      <div className="text">
        Under min uppväxt tecknade jag dagligen, och intresset var ett brinnande sådant. Efter barndomen gick intresset för tecknandet i vågor tills jag tillslut fick upp ögonen för tatuering, och därmed hamnade jag som lärling på en lokal tatueringsstudio. Tatuerandet var enormt utvecklande då det ingick i min dagliga arbetsrutin att vara kreativ, uppleva kundkontakt samt arbeta disciplinerat och väldigt noggrant under alla moment.
        Det är inte omöjligt att det var den disciplinen som fick mig att så småningom börja måla realism, då det kräver en stor noggrannhet och koncentration för att uppnå det resultat jag vill ha.
        <br/><br/>
        Jag arbetade sedan på tatueringsstudion i ett par år, och plötsligt stod familjelivet framför dörren. Återigen hamnade tecknandet lite mer i skymundan mellan barn, arbete, plugg och ännu ett barn. 
        <br/><br/>
        2018 bestämde jag mig för att måla en tavla. Jag hade då inte målat med pensel och färg på många år utan istället tecknat/målat digitalt när små stunder av tid fanns. Jag var höggravid, och la över trettio långa timmar på min första “riktiga” målning. När jag tillslut tog några steg tillbaka och betraktade min målning ropade jag lyckligt till min man Sebastian: “Jag kan visst måla!”
        <br/><br/>
        Efter den stunden smög sig känslan på mig. Att det var precis det här jag ville göra. Och efter att ha lekt med tanken, känt och klämt på den under ett års tid medan jag var föräldraledig med mitt andra barn, vågade jag hösten 2019 uttala orden: “jag vill bli konstnär.”
        <br/><br/>
        Så nu gör jag det här.
        <br/><br/>
        Målningarna du ser här är resultatet av mina första ca 200 timmar av akrylmåleri. Början på min konstnärsresa, utforskande av både form, färg och teknik. Den enda röda tråden jag själv lyckats se i mitt målande är att jag vill måla allt. Kanske för att jag tycker mig se något i ett ögonblick, ofta ett väldigt vardagligt sådant, något enormt stort i det vardagliga. Och att få plocka ut just det där ögonblicket, och studera dess detaljer är för mig fantastiskt. Det hjälper mig att se världen på ett sätt som ger mig välbefinnande och närhet till livet självt.
      </div>
      <Social />
    </div>
  );
}
