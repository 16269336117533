import React from 'react'
import { NavLink } from 'react-router-dom';
import Social from '../../components/Social/Social';

import "./style.css"

function Start() {
  return (
    <div className="Start">
      <div className="links">
        <NavLink to="/galleri" activeClassName="active">Galleri</NavLink>
        <NavLink to="/buyart" activeClassName="active">Köp konst</NavLink>
        <NavLink to="/ommig" activeClassName="active">Om mig</NavLink>
        <Social />
      </div>
    </div>
  );
}

export default Start
