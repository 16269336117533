import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons'

import './style.css';

export default () => {

  return (
    <div className="Social row">
      <a target="__blank" href="http://www.instagram.com/emmajelkart">
        <FontAwesomeIcon icon={faInstagram} />
      </a>
      <a target="__blank" href="http://www.facebook.com/emma.jelk">
        <FontAwesomeIcon icon={faFacebookF} className={"smaller"} />
      </a>
    </div>
  )
}