import React from 'react';
import Header from './components/Header/Header';
import { Route, useLocation } from 'react-router-dom';

import Start from './routes/Start/Start';
import About from './routes/About/About';
import Gallery from './routes/Gallery/Gallery';

import emma from "./resources/cold_fire_hq.jpg"
import BuyArt from './routes/BuyArt/BuyArt';
import VirtualGallery from './routes/VirtualGallery/VirtualGallery';

import './App.css';
import Upload from './routes/Upload/Upload';

function App() {
  const location = useLocation()
  return (
    <div className="App" style={{
      backgroundImage:location.pathname === "/" ? `url(${emma})` : ""
    }}>
      <Header />
      <Route path="/" exact={true} component={Start} />
      <Route path="/buyart" component={BuyArt} />
      <Route path="/galleri" component={Gallery} />
      <Route path="/ommig" component={About} />
      <Route path="/upload" component={Upload} />
      <Route path="/virtualgallery" component={VirtualGallery} />
    </div>
  );
}

export default App;
