import { Scene, Vector3, Color, PerspectiveCamera, Raycaster } from "three";
import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls';

let moveForward = false;
let moveBackward = false;
let moveLeft = false;
let moveRight = false;
let canJump = false;

let prevTime = performance.now();

const raycaster = new Raycaster( new Vector3(), new Vector3( 0, - 1, 0 ), 0, 10 );
const velocity = new Vector3();
const direction = new Vector3();

const vertex = new Vector3();
const color = new Color();

let controls: PointerLockControls | undefined = undefined

export const InitControls = (scene: Scene, camera: PerspectiveCamera, blocker: HTMLElement, instructions: HTMLElement ) => {
  controls = new PointerLockControls( camera, document.body );

  instructions.addEventListener( 'click', function () {
    controls?.lock();
  } );

  controls.addEventListener( 'lock', function () {
    instructions.style.display = 'none';
    blocker.style.display = 'none';
  } );

  controls.addEventListener( 'unlock', function () {

    blocker.style.display = 'block';
    instructions.style.display = '';

  } );

  scene.add( controls.getObject() );

  const onKeyDown = function ( event: any ) {
    switch ( event.code ) {
      case 'ArrowUp':
      case 'KeyW':
        moveForward = true;
        break

      case 'ArrowLeft':
      case 'KeyA':
        moveLeft = true;
        break

      case 'ArrowDown':
      case 'KeyS':
        moveBackward = true;
        break

      case 'ArrowRight':
      case 'KeyD':
        moveRight = true;
        break

      case 'Space':
        if ( canJump === true ) velocity.y += 350;
        canJump = false;
        break
    }
  }

  const onKeyUp = function ( event: any ) {
    switch ( event.code ) {
      case 'ArrowUp':
      case 'KeyW':
        moveForward = false;
        break

      case 'ArrowLeft':
      case 'KeyA':
        moveLeft = false;
        break

      case 'ArrowDown':
      case 'KeyS':
        moveBackward = false;
        break

      case 'ArrowRight':
      case 'KeyD':
        moveRight = false;
        break
    }
  }

  document.addEventListener( 'keydown', onKeyDown );
  document.addEventListener( 'keyup', onKeyUp );
}

export const updateControls = (delta:number) => {
  
  const time = performance.now();

  if ( !controls ) {
    return
  }

  if ( controls.isLocked === true ) {

    raycaster.ray.origin.copy( controls.getObject().position );
    raycaster.ray.origin.y -= 10;

    const intersections = raycaster.intersectObjects( [], false );

    const onObject = intersections.length > 0;

    velocity.x -= velocity.x * 10 * delta;
    velocity.z -= velocity.z * 10 * delta;

    //velocity.y -= 9.8 * 100.0 * delta; // 100.0 = mass

    console.log("move forward", moveForward)
    direction.z = Number( moveForward ) - Number( moveBackward );
    direction.x = Number( moveRight ) - Number( moveLeft );
    direction.normalize(); // this ensures consistent movements in all directions

    if ( moveForward || moveBackward ) velocity.z -= direction.z * 100 * delta;
    if ( moveLeft || moveRight ) velocity.x -= direction.x * 100 * delta;

    if ( onObject === true ) {

      //velocity.y = Math.max( 0, velocity.y );
      canJump = true;

    }

    controls.moveRight( - velocity.x * delta );
    controls.moveForward( - velocity.z * delta );

    //controls.getObject().position.y += ( velocity.y * delta ); // new behavior

    if ( controls.getObject().position.y < 10 ) {

      velocity.y = 0;
      controls.getObject().position.y = 10;

      canJump = true;
    }
  }

  prevTime = time;
}