import { Scene, WebGLRenderer, Vector2, Camera, ToneMapping, sRGBEncoding } from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { ACESFilmicToneMappingShader } from './ACESFilmicToneMapping';
import { GammaCorrectionShader } from './GammaCorrectionShader';

let composer: EffectComposer | undefined = undefined;

const params = {
  exposure: 0.7,
  bloomStrength: 0.4,
  bloomThreshold: 0.8,
  bloomRadius: 0.2
};

let bloomPass: UnrealBloomPass | undefined = undefined

export const getBloomPass = () => bloomPass

export const InitEffects = (camera: Camera, scene: Scene, renderer: WebGLRenderer) => {

  renderer.render(scene, camera)
  
  const renderScene = new RenderPass( scene, camera )

  bloomPass = new UnrealBloomPass( new Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 )
  bloomPass.threshold = params.bloomThreshold
  bloomPass.strength = params.bloomStrength
  bloomPass.radius = params.bloomRadius

  const tonemappingPass = new ShaderPass(ACESFilmicToneMappingShader)
  const gammaPass = new ShaderPass(GammaCorrectionShader)
  
  composer = new EffectComposer( renderer )
  composer.addPass( renderScene )
  //composer.addPass( bloomPass )
  composer.addPass( tonemappingPass )
  //composer.addPass( gammaPass )
  
  //composer.renderTarget1.texture.encoding = sRGBEncoding;
  //composer.renderTarget2.texture.encoding = sRGBEncoding;
}

export const renderEffects = (delta: number) => {
  composer?.render();
}