import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { FileUploader } from "react-drag-drop-files";
import "./style.css"

const serviceURI = 'https://row4mwu68c.execute-api.eu-north-1.amazonaws.com/production/upload'

const fileTypes = ["JPG", "PNG", "GIF"];

export default () => {
  const fileInputRef = useRef<HTMLInputElement|null>(null)
  const buttonRef = useRef<HTMLButtonElement|null>(null)
  const [selectedFile, setSelectedFile] = useState<File>()
  const [passkey, setPassKey] = useState('')
  const [resultURL, setResultURL] = useState<string>()

  useEffect(() => {
    const savedPassKey = localStorage.getItem('savedkey')
    if (savedPassKey) {
      setPassKey(savedPassKey)
    }
  }, [])

  const upload = async (file?: File) => {
    const _file = file ?? selectedFile
    if (!_file) {
      return
    }

    const formData = new FormData();
    formData.append("file", _file);

    try {
      const result = await axios.post(serviceURI, formData, {
        headers: {
          'Content-Type': 'application/octet-stream',
          'Authorization': passkey
        }
      })
      console.log("File Upload success", result);
      setResultURL(result.data.imageUrl)

      localStorage.setItem('savedkey', passkey)
    } catch(e) {
      console.error('fail', e)
    }
  }

  const handleChange = (file: any) => {
    upload(file);
  };

  const copy = () => {
    if (resultURL) {
      navigator.clipboard.writeText(resultURL);

      if (buttonRef.current) {
        buttonRef.current.innerText = 'Copied'
      }
    }
  }
  
  return (
    <div className="About text-page">
      <div className='flex'>
        <div>Passkey:</div>
        <div>
          <input value={passkey} accept="image/*" type="text" onChange={(e) => setPassKey(e.target.value)} />
        </div>
      </div>
      <div className="spacer"></div>

      <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
      <div className="spacer"></div>
      {resultURL && 
        <>
          <div>
            <a href={resultURL} download>
              {resultURL}
            </a>
          </div>
          
          <div className="spacer"></div>
          <button ref={buttonRef} onClick={copy}>Copy</button>
        </>
      }
    </div>
  );
}
