import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import NavMenu from '../NavMenu/NavMenu';

import './style.css';

export default () => {

  const route = useLocation()
  const isHome = useMemo(() => route.pathname.length === 1, [route.pathname])
  const onVirtualGallery = useMemo(() => route.pathname.indexOf("virtual") !== -1, [route.pathname])
  
  return (
    <div className={"Header row" + (isHome ? " home" : "") + (onVirtualGallery ? " virtualgallery" : "")}>
      <a className="title" href="/">
        Emma Jelk 
      </a>
      <NavMenu />
    </div>
  )
}

/*

      <Social />
      */