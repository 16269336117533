import React, { useMemo, useState } from 'react';

import './style.css';
import { NavLink, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import Social from '../Social/Social';

export default () => {

  const [menuOpen, setMenuOpen] = useState(false)
  
  const route = useLocation()
  const isHome = useMemo(() => route.pathname.length === 1, [route.pathname])

  return (
    <div className={"NavMenu" + (isHome ? " home" : "")}>
      <div className="row">
        <NavLink to="/" exact={true} activeClassName="active">Hem</NavLink>
        <NavLink to="/galleri" activeClassName="active">Galleri</NavLink>
        <NavLink to="/buyart" activeClassName="active">Köp konst</NavLink>
        <NavLink to="/ommig" activeClassName="active">Om mig</NavLink>
        <Social />
      </div>
      {menuOpen && (
        <div className="open-burger" onClick={() => setMenuOpen(false)}>
          <NavLink to="/" exact={true} activeClassName="active">Hem</NavLink>
          <NavLink to="/galleri" activeClassName="active">Galleri</NavLink>
          <NavLink to="/buyart" activeClassName="active">Köp konst</NavLink>
          <NavLink to="/ommig" activeClassName="active">Om mig</NavLink>
        </div>
      )}
      <div className="burger" onClick={() => setMenuOpen(!menuOpen)}>
        {menuOpen ? <FontAwesomeIcon icon={faTimes} className={"opened"} /> : <FontAwesomeIcon icon={faBars} />}
      </div>
    </div>
  )
}