import React, { useEffect, useRef, useState } from 'react'
import { AmbientLight, Color, DirectionalLight, Mesh, MeshStandardMaterial, Object3D, PerspectiveCamera, Scene, TextureLoader, WebGLRenderer } from "three"
import * as THREE from "three"
import { fetchModel } from '../../utils/mesh-utils'
import CameraControls from 'camera-controls';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'

import "./style.css"
import { InitControls, updateControls } from './controls';
import { getBloomPass, InitEffects, renderEffects } from './effects';

CameraControls.install( { THREE } );

export default () => {
  const ref = useRef<HTMLDivElement|null>(null)

  const blockerRef = useRef<HTMLDivElement|null>(null)
  const instructionsRef = useRef<HTMLDivElement|null>(null)

  const [bloomStrength, setBloomStrength] = useState(0.1)
  const [bloomThreshold, setBloomThreshold] = useState(0.9)
  const [bloomRadius, setBloomRadius] = useState(0.1)

  useEffect(() => {

    if(ref.current && blockerRef.current && instructionsRef.current) {
      const container = ref.current

      const scene = new Scene()
      const backgroundColor = new Color(255, 255, 255)
      scene.background = new Color(backgroundColor)
      
      const camera = new PerspectiveCamera( 70, window.innerWidth / window.innerHeight, 0.1, 300 );
      const renderer = new WebGLRenderer( { antialias: true } )
      renderer.setSize( window.innerWidth, window.innerHeight );
      
      new RGBELoader().load(process.env.PUBLIC_URL + '/kloppenheim_05_1k.hdr', (texture) => {
				texture.mapping = THREE.EquirectangularReflectionMapping;
        
				scene.background = texture;
				//scene.environment = texture;
      })
      
      InitControls(scene, camera, blockerRef.current, instructionsRef.current)
      InitEffects(camera, scene, renderer)

      camera.position.set(0, 2, 3)

      //const ambient = new AmbientLight(new Color("#FFFFFF"), 0.1)
      //scene.add( ambient )

      //const directional = new DirectionalLight(new Color("#FFFFEF"), 0.8)
      //const targetObject = new Object3D()
      //directional.position.set(0.5, 1, 0.5)
      //directional.target = targetObject
      //scene.add( directional )

      container.appendChild( renderer.domElement )

      let lastTime = 0
      let renderRequested = true

      const frame = (time: number) => {
        if(lastTime === 0) {
          lastTime = time
        }

        const delta = time - lastTime
        updateControls( delta / 1000.0 )
        
        renderer.render( scene, camera )
        
        //renderEffects( delta / 1000.0 )
        renderRequested = false
        
        lastTime = time
      }

      renderer.setAnimationLoop( frame );

      THREE.ShaderChunk.tonemapping_pars_fragment = THREE.ShaderChunk.tonemapping_pars_fragment.replace(
        'vec3 CustomToneMapping( vec3 color ) { return color; }',
        `#define Uncharted2Helper( x ) max( ( ( x * ( 0.15 * x + 0.10 * 0.50 ) + 0.20 * 0.02 ) / ( x * ( 0.15 * x + 0.50 ) + 0.20 * 0.30 ) ) - 0.02 / 0.30, vec3( 0.0 ) )
        float toneMappingWhitePoint = 1.0;
        vec3 CustomToneMapping( vec3 color ) {
          color *= toneMappingExposure;
          return saturate( Uncharted2Helper( color ) / Uncharted2Helper( vec3( toneMappingWhitePoint ) ) );
        }`
      );

      renderer.toneMapping = THREE.ACESFilmicToneMapping;
      renderer.toneMappingExposure = 1.0;

      //renderer.outputEncoding = THREE.LinearEncoding
      renderer.outputEncoding = THREE.sRGBEncoding;
      
      const loadTexture = (textureName:string, material: MeshStandardMaterial) => {
        const loader = new RGBELoader()
        loader.load(process.env.PUBLIC_URL + "/meshes/textures/" + textureName, (texture) => {
          texture.flipY = true
          texture.needsUpdate = true

          material.lightMap = texture
          material.lightMapIntensity = 1.0
          material.needsUpdate = true
          renderRequested = true
        })
      }
      
      new TextureLoader().load(process.env.PUBLIC_URL + "/white.png", (whiteTex) => {
        fetchModel("gallery", (galleryObject) => {
          scene.add( galleryObject )
          galleryObject.scale.set(2.8, 2.8, 2.8)
          renderRequested = true
  
          let archesMaterial = new MeshStandardMaterial()
          loadTexture("Arches_denoised.hdr", archesMaterial)

          galleryObject.traverse((obj) => {
            if((obj as Mesh).isMesh) {
  
              if(obj.name.indexOf('A') !== 0) {
                const mesh = (obj as Mesh)
                const material = new MeshStandardMaterial()
                mesh.material = material

                const textureName = mesh.name.replace(".", "") + "_denoised.hdr"
                loadTexture(textureName, material)
              }
              else {
                const archesMesh = (obj as Mesh)
                archesMesh.material = archesMaterial
              }
            }
          })
        })
      })

      return () => {
        renderer.setAnimationLoop( null );
      }
    }
  }, [])
  return (
    <div ref={ref} className='VirtualGallery'>
      <div ref={blockerRef} className='blocker'>
      </div>
      <div className="controls">
        <div>
          <div >Exposure {bloomStrength}</div>
          <input type="range" value={bloomStrength * 50.0} min={1} max={50} onChange={(e) => {
            const bp = getBloomPass()

            if(bp) {
              setBloomStrength(parseFloat(e.currentTarget.value) / 50.0)
              bp.strength = parseFloat(e.currentTarget.value) / 50.0
            }
          }}>

          </input>
        </div>
        <div>
          <div >Threshold {bloomThreshold}</div>
          <input type="range" value={bloomThreshold * 50.0} min={1} max={50} onChange={(e) => {
            const bp = getBloomPass()

            if(bp) {
              setBloomThreshold(parseFloat(e.currentTarget.value) / 50.0)
              bp.threshold = parseFloat(e.currentTarget.value) / 50.0
            }
          }}>

          </input>
        </div>
        <div>
          <div >Radius {bloomRadius}</div>
          <input type="range" value={bloomRadius * 50.0} min={1} max={50} onChange={(e) => {
            const bp = getBloomPass()

            if(bp) {
              setBloomRadius(parseFloat(e.currentTarget.value) / 50.0)
              bp.radius = parseFloat(e.currentTarget.value) / 50.0
            }
          }}>

          </input>
        </div>
      </div>
      <div ref={instructionsRef} className='instructions'>
        <p style={ {fontSize: 36} }>
          Click to play
        </p>
        <p>
          Move: WASD<br/>
          Jump: SPACE<br/>
          Look: MOUSE
        </p>
      </div>
    </div>
  );
}