import React from 'react'
import pic_on_wall from "../../resources/pic_on_wall.jpg"

import "./style.css"

function Start() {
  return (
    <div className="BuyArt text-page">
      <img src={pic_on_wall} />
      <div className="text">
        <h2>Köp konst</h2>
        <div className="text">
        Under fliken galleri hittar du mina originalmålningar, både tillgängliga och sålda verk. 
        Är du intresserad av att köpa originalkonst och vill få mer information om ett specifikt verk, skicka ett mail till: emma.jelk@gmail.com
      </div>
      </div>
    </div>
  );
}

export default Start
