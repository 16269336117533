import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import './style.css';

import mother from "../../resources/mother.jpg" 

import teeth from "../../resources/teeth.jpg" 
import teeth_thumb from "../../resources/teeth_thumb.jpg" 

import tape from "../../resources/tape.jpg" 
import tape_thumb from "../../resources/tape_thumb.jpg" 

import yard1 from "../../resources/yard1.jpg" 
import yard1_thumb from "../../resources/yard1_thumb.jpg" 

import hair from "../../resources/hair.jpg" 
import hair_thumb from "../../resources/hair_thumb.jpg" 

import agren from "../../resources/agren.jpg" 
import agren_thumb from "../../resources/agren_thumb.jpg"
 
import hund from "../../resources/100000.jpg" 

import born from "../../resources/born.jpg" 
import born_thumb from "../../resources/born_thumb.jpg"

import yard2 from "../../resources/yard2.jpg" 
import yard2_thumb from "../../resources/yard2_thumb.jpg"

import pourguy from "../../resources/pourguy.jpg" 
import pourguy_thumb from "../../resources/pourguy_thumb.jpg"

import hungrig from "../../resources/hungrig.jpg" 
import hungrig_thumb from "../../resources/hungrig_thumb.jpg"

import sjalvportratt from "../../resources/sjalvportratt.jpg" 
import sjalvportratt_thumb from "../../resources/sjalvportratt_thumb.jpg"

import hall_kaften_tommy from "../../resources/hall_kaften_tommy.jpg" 
import hall_kaften_tommy_thumb from "../../resources/hall_kaften_tommy_thumb.jpg"

import grimas2 from "../../resources/grimas2.jpg" 
import grimas2_thumb from "../../resources/grimas2_thumb.jpg"

import things from "../../resources/things.jpg" 
import things_thumb from "../../resources/things_thumb.jpg"

import fluids from "../../resources/fluids.jpg" 
import fluids_thumb from "../../resources/fluids_thumb.jpg"

import floating from "../../resources/floating.jpg" 
import floating_thumb from "../../resources/floating_thumb.jpg"

import pandemicspring from "../../resources/pandemicspring.jpg" 
import pandemicspring_thumb from "../../resources/pandemicspring_thumb.jpg"

import villebrad from "../../resources/villebrad.jpg" 
import villebrad_thumb from "../../resources/villebrad_thumb.jpg"

import hope from "../../resources/hope.jpg" 
import hope_thumb from "../../resources/hope_thumb.jpg"

import dodskalle from "../../resources/dodskalle.jpg" 
import dodskalle_thumb from "../../resources/dodskalle_thumb.jpg"

import mother_n_son from "../../resources/mother_n_son.jpg" 
import mother_n_son_thumb from "../../resources/mother_n_son_thumb.jpg"

import landing from "../../resources/landing.jpg" 
import landing_thumb from "../../resources/landing_thumb.jpg"

import onlynow from "../../resources/onlynow.jpg" 
import onlynow_thumb from "../../resources/onlynow_thumb.jpg"

import interlinked from "../../resources/interlinked.jpg" 
import interlinked_thumb from "../../resources/interlinked_thumb.jpg"

import divided from "../../resources/divided.jpg" 
import divided_thumb from "../../resources/divided_thumb.jpg"

import mejeriet from "../../resources/mejeriet.jpg" 
import mejeriet_thumb from "../../resources/mejeriet_thumb.jpg"

import against_the_dying from "../../resources/against_the_dying.jpg" 
import against_the_dying_thumb from "../../resources/against_the_dying_thumb.jpg"

import a_sky_of_age from "../../resources/a_sky_of_age.jpg" 
import a_sky_of_age_thumb from "../../resources/a_sky_of_age_thumb.jpg"

import ogden from "../../resources/ogden.jpg" 
import ogden_thumb from "../../resources/ogden_thumb.jpg"

import nautilus from "../../resources/nautilus.jpg" 
import nautilus_thumb from "../../resources/nautilus_thumb.jpg"

import sojourner from "../../resources/sojourner.jpg" 
import sojourner_thumb from "../../resources/sojourner_thumb.jpg"

import truth_neverleft from "../../resources/truth_neverleft.jpg" 
import truth_neverleft_thumb from "../../resources/truth_neverleft_thumb.jpg"

import terra_mater from "../../resources/terra_mater.jpg" 
import terra_mater_thumb from "../../resources/terra_mater_thumb.jpg"

import something_hold_on from "../../resources/something_hold_on.jpg" 
import something_hold_on_thumb from "../../resources/something_hold_on_thumb.jpg"

import pontus from "../../resources/pontus.jpg" 
import pontus_thumb from "../../resources/pontus_thumb.jpg"

import mindthegap from "../../resources/mindthegap.jpg" 
import mindthegap_thumb from "../../resources/mindthegap_thumb.jpg"

import nexus from "../../resources/nexus.jpg" 
import nexus_thumb from "../../resources/nexus_thumb.jpg"

import eyeonroad from "../../resources/eyeonroad.jpg" 
import eyeonroad_thumb from "../../resources/eyeonroad_thumb.jpg"

import home from "../../resources/home.jpg" 
import home_thumb from "../../resources/home_thumb.jpg"

import giants from "../../resources/giants.jpg" 
import giants_thumb from "../../resources/giants_thumb.jpg"

import fallout from "../../resources/fallout.jpg" 
import fallout_thumb from "../../resources/fallout_thumb.jpg"

import cold_fire from "../../resources/cold_fire.jpg" 
import cold_fire_thumb from "../../resources/cold_fire_thumb.jpg"

import flays from "../../resources/flays.jpg" 
import flays_thumb from "../../resources/flays_thumb.jpg"

const i = Object.keys

const gallery : {[key:string]:any} = {
  home: home,
  sojourner: sojourner,
  cold_fire: cold_fire,
  mindthegap: mindthegap,
  flays: flays,
  terra_mater: terra_mater,
  giants: giants,
  fallout: fallout,
  nexus: nexus,
  truth_neverleft: truth_neverleft,
  nautilus: nautilus,
  pontus: pontus,
  against_the_dying: against_the_dying,
  eyeonroad: eyeonroad,
  a_sky_of_age:a_sky_of_age,
  something_hold_on: something_hold_on,
  ogden: ogden,

  divided:divided,
  mejeriet:mejeriet,
  interlinked:interlinked,
  onlynow:onlynow,
  landing:landing,
  mother_n_son:mother_n_son,
  hope:hope,
  born:born,
  floating:floating,
  hall_kaften_tommy:hall_kaften_tommy,
  things:things,
  dodskalle:dodskalle,
  villebrad:villebrad,
  mother:mother,
  pandemicspring:pandemicspring,
  hungrig:hungrig,
  pourguy:pourguy,
  fluids:fluids,
  sjalvportratt:sjalvportratt,
  yard1:yard1,
  hair:hair,
  grimas2:grimas2,
  tape:tape,
  teeth:teeth,
  yard2:yard2,
  hund:hund,
  //agren:agren,
}

const galleryThumbs : {[key:string]:any} = {
  fallout: fallout_thumb,
  home: home_thumb,
  flays: flays_thumb,
  sojourner: sojourner_thumb,
  cold_fire: cold_fire_thumb,
  giants: giants_thumb,
  against_the_dying:against_the_dying_thumb,
  truth_neverleft: truth_neverleft_thumb,
  terra_mater: terra_mater_thumb,
  nautilus: nautilus_thumb,
  mindthegap: mindthegap_thumb,
  nexus: nexus_thumb,
  pontus: pontus_thumb,
  a_sky_of_age:a_sky_of_age_thumb,
  eyeonroad: eyeonroad_thumb,
  something_hold_on: something_hold_on_thumb,
  ogden: ogden_thumb,

  divided:divided_thumb,
  mejeriet:mejeriet_thumb,
  interlinked:interlinked_thumb,
  onlynow:onlynow_thumb,
  landing:landing_thumb,
  mother_n_son:mother_n_son_thumb,
  hope:hope_thumb,
  dodskalle:dodskalle_thumb,
  villebrad:villebrad_thumb,
  pandemicspring:pandemicspring_thumb,
  floating:floating_thumb,
  fluids:fluids_thumb,
  hall_kaften_tommy:hall_kaften_tommy_thumb,
  grimas2:grimas2_thumb,
  hair:hair_thumb,
  pourguy:pourguy_thumb,
  hungrig:hungrig_thumb,
  sjalvportratt:sjalvportratt_thumb,
  born:born_thumb,
  tape:tape_thumb,
  yard2:yard2_thumb,
  teeth:teeth_thumb,
  yard1:yard1_thumb,
  agren:agren_thumb,
  things:things_thumb,
}

interface Info {
  sold?:boolean
  size?:string
  name?:string
}

const info : {[key:string]:Info} = {
  flays: {
    sold: false,
    size: "93x68",
    name: "And she flays",
  },
  cold_fire: {
    sold: false,
    size: "99x69",
    name: "Cold fire"
  },
  fallout: {
    sold: false,
    size: "74x57",
    name: "Fallout"
  },
  home: {
    sold: true,
    size: "50x61",
    name: "H.O.M.E"
  },
  giants: {
    sold: false,
    size: "116x89",
    name: 'Giants'
  },
  sojourner: {
    sold: true,
    size: "65x54",
    name: "The sojourner"
  },
  eyeonroad: {
    sold: false,
    size: '61x50',
    name: "I'll keep an eye on the road",
  },
  mindthegap:{
    sold: false,
    size: '120x85',
    name: 'Please mind the gap',
  },
  nexus:{
    sold: false,
    size: '70x100',
    name: 'Nexus',
  },
  pontus: {
    sold: false,
    size: '61x50',
    name: 'Pontus',
  },
  something_hold_on: {
    sold: false,
    size: '100x80',
    name: 'Something to hold on to',
  },
  terra_mater: {
    sold: false,
    size: "93x68",
    name: 'Terra Mater',
  },
  truth_neverleft: {
    sold: true,
    size: "62x43",
    name: "The truth is, I never really left"
  },
  nautilus: {
    sold: false,
    size: "89x116",
    name: "Nautilus"
  },
  against_the_dying: {
    sold: false,
    size: "104x121",
    name: "Against the dying of the light"
  },
  ogden: {
    sold: false,
    size: "70x49",
    name: "Whenever we entered the field, Mr Ogden would come out screaming at us"
  },
  a_sky_of_age: {
    sold: false,
    size: "120x85",
    name: "A sky of age"
  },
  mejeriet: {
    sold:false,
    size:"50x70",
    name:"Mejeriet"
  },
  divided: {
    sold:true,
    size:"80x80",
    name:"Divided"
  },
  landing: {
    sold:true,
    size:"117x69",
    name:"The landing area is a total mess",
  },
  interlinked: {
    sold:true,
    size:"60x50",
    name:"Interlinked",
  },
  onlynow: {
    sold:false,
    size:"70x50",
    name:"There is only now",
  },
  hope: {
    sold:false,
    size:"90x106",
    name:"Hope dies last",
  },
  villebrad: {
    sold:false,
    name:"Villebråd 2021",
  },
  pandemicspring: {
    sold:false,
    name:"Pandemic spring",
    size:"100x100"
  },
  mother_n_son: {
    sold:false,
    name:"The mother and the son",
    size:"90x110"
  },
  hall_kaften_tommy:{
    sold:true,
    name:"Håll käften, Tommy",
    size:"100x70"
  },
  floating:{
    sold:false,
    name:"Floating on the surface of a planet",
    size:"98x70"
  },
  fluids:{
    sold:false,
    name:"Amniotic Fluids Spa & Resort",
    size:"95x95"
  },
  pourguy:{
    sold:false,
    name:"Pour guy",
    size:"90x100"
  },
  born:{
    sold:true,
    name:"Rebirth",
    size:"70x100"
  },
  hund:{
    sold:true,
    name:"100 000",
    size:"70x70"
  },
  br_2:{
    sold:true,
    name:"BR 2",
    size:"100x50"
  },
  br_desert_small4:{
    sold:true,
    name:"BR 1",
    size:"100x50"
  },
  mother:{
    sold:true,
    name:"Mother",
    size:"70x70"
  },
  grimas2:{
    sold:true,
    name:"Grimas 2",
    size:"70x70"
  },
  hungrig: {
    sold:false,
    name:"Hungrig"
  },
  yard2: {
    sold:true
  },
  teeth:{
    sold:true,
    name:"Donut hole",
    size:"70x70"
  },
  things:{
    sold:false,
    name:"The things we leave behind",
    size:"120x95"
  }
}

const getFSIndex = (image:string) => Object.keys(gallery).indexOf(image)

export default () => {
  const history = useHistory()
  const location = useLocation()
  const fullscreen = window.location.href.indexOf("#") ? window.location.href.split('#')[1] : "none"
  const [fullscreenIndex, setFullscreenIndex] = useState(getFSIndex(location.hash.replace("#", "")))

  const [limit, setLimit] = useState(8)

  useEffect(() => setFullscreenIndex(getFSIndex(location.hash.replace("#", ""))), [location.hash])

  const go = useCallback((e:any, delta:number) => {
    history.replace("/galleri#" + Object.keys(gallery)[fullscreenIndex + delta])
  }, [fullscreenIndex, history])

  const onClose = useCallback((e:any) => {
    if(e.defaultPrevented)
      return

    e.preventDefault()
    if(history.length > 2) {
      history.goBack()
    }
    else {
      history.replace("#")
    }
  }, [history])

  useEffect(() => {
    const keyPress = (e: KeyboardEvent) => {
      console.log(e.key)
      if (e.key === 'Escape') {
        onClose(e)
      }
    }

    document.addEventListener("keypress", keyPress)

    return () => {
      document.removeEventListener("keypress", keyPress)
    }
  }, [onClose])

  const { galleryToShow, hasMore } = useMemo(() => {
    if (limit > i(gallery).length) {
      return {
        galleryToShow: i(gallery),
        hasMore: false
      }
    }
    return {
      galleryToShow: i(gallery).slice(0, limit),
      hasMore: true
    }
  }, [gallery, limit])

  return (
    <>
      <div className={"GridGallery"}>
        {galleryToShow.map(gItem => (
          <a key={gItem} href={"#" + gItem} className={"item" + (info[gItem] && info[gItem].sold ?  " sold" : "")}>
            <img src={galleryThumbs[gItem] || gallery[gItem]} />
          </a>
        ))}
        {gallery[fullscreen] && (
          <div className="fullscreen" onClick={onClose}>
            <img src={gallery[fullscreen]} />
            <div className="nextprev" onClick={onClose}>
              <div className={"prev " + (fullscreenIndex === 0 ? "inactive" : "")} onClick={(e) => {          
                e.stopPropagation()
                e.preventDefault()
                if(fullscreenIndex > 0)
                  go(e, -1)
              }}>
              </div>
              <div className={"next" + (fullscreenIndex >= (Object.keys(gallery).length - 1) ? " inactive": "")} onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                if(fullscreenIndex < (Object.keys(gallery).length - 1))
                  go(e, 1)
              }}>
              </div>
            </div>
            <div className="close">
              Stäng
            </div>
            {fullscreenIndex !== -1}
            {info[fullscreen] && (
              <div className="info">
                {info[fullscreen].sold && <div>[SÅLD]</div>}
                {info[fullscreen].name && <div>{info[fullscreen].name}</div>}
                {info[fullscreen].size && <div>{info[fullscreen].size}</div>}
              </div>
            )}
          </div>
        )}
      </div>
      {hasMore && 
        <div className="show-more-wrapper">
          <button onClick={() => setLimit(limit + 8)}>Show more</button>
        </div>
      }
    </>
  )
}