import { Object3D, Group } from "three"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"

const meshCache:{[key:string]:Object3D} = {}
const callbacks:{[key:string]:((loadedMesh:Object3D) => void)[]} = {}

const meshBaseURL = process.env.PUBLIC_URL + "/meshes/"

export const fetchModelAsync = async (meshName:string, subObjectName?:string) => {
  const loaded = await new Promise<Object3D>((resolve, reject) => {
    try {
      fetchModel(meshName, resolve, subObjectName)
    } catch(e) {
      reject(e)
    }
  })
  return loaded
}

export const fetchModel = (meshName:string, onLoad:(loadedScene:Object3D) => void, subObjectName?:string) => {
  if(meshCache[meshName]) {
    onLoad(meshCache[meshName])
  }
  else {
    if(!callbacks[meshName]) {
      callbacks[meshName] = [ onLoad ]
      loadAsync(meshName)
    }
    else {
      callbacks[meshName].push(onLoad)
    }
  }
}

const loadAsync = async (meshName:string) => {
  const loader = new GLTFLoader()

  const loadedScene = (await loader.loadAsync(meshBaseURL + meshName + ".glb")).scene as Group

  callbacks[meshName].forEach(cb => {
    cb(loadedScene)
  })
}
  